import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { Progress } from '@/components/ui/progress';
import { ZapIcon } from 'lucide-react';
import { SurveyContext } from './SurveyContext';
import { Dialog, DialogTrigger, DialogContent } from '@/components/ui/dialog';
import UpgradePlan from './UpgradePlan';

const SubmissionsCount = () => {
  const { submissionsCount } = useContext(SurveyContext);
  const [progress, setProgress] = useState(0);
  const [maxResponses, setMaxResponses] = useState(20);
  const { user } = useAuth0();

  useEffect(() => {
    const fetchSubscription = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/get-subscription/${user.sub}`);
        const subscription = response.data.subscription;

        if (subscription.plan.id === 'price_1QAm9jK8hA4d0bD44wXJhK3G') {
          setMaxResponses(20); // Free Plan
        } else if (subscription.plan.id === 'price_1Q9wPuK8hA4d0bD4Y50JXrHi') {
          setMaxResponses(200); // Pro Plan
        } else if (subscription.plan.id === 'price_1Q9wQeK8hA4d0bD4btKimYpZ') {
          setMaxResponses(500); // Business Plan
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          console.warn("Subscription not found, using default max responses.");
          setMaxResponses(20); // Default to Free Plan if no subscription found
        } else {
          console.error("Error fetching subscription:", error);
        }
      }
    };

    if (user && user.sub) {
      fetchSubscription();
    }
  }, [user]);

  useEffect(() => {
    setProgress((submissionsCount / maxResponses) * 100);
  }, [submissionsCount, maxResponses]);

  return (
    <div className="w-[220px] p-4 bg-white border border-gray-300 rounded-md shadow-sm">
      <div className="text-sm font-medium text-gray-700">{submissionsCount} of {maxResponses} free responses</div>
      <Progress value={progress} className="mt-2" />

      <Dialog>
        <DialogTrigger asChild>
          <button className="flex mt-4 w-full justify-center bg-[#0C003F] hover:bg-[#0C003F] font-semibold py-2 rounded-lg shadow-md transition duration-300 ease-in-out">
            <ZapIcon className='fill-white text-white' />
            <div>
              <div className='ml-2 text-white cursor-pointer' >Upgrade</div>
            </div>
          </button>
        </DialogTrigger>
        <DialogContent className="max-w-[800px]">
          <UpgradePlan />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default SubmissionsCount;