import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Spinner from "./Spinner";

const stripePromise = loadStripe("pk_live_51N8x8TK8hA4d0bD4LYEQFEPYXSnrcblhXGUoJwy22V34cQT2Cvj1lJqfSz8w2HXuKKJKkVMJptXbDdAhXyphoCLZ00XKqK1yx8");

const Plans = () => {
  const [subscription, setSubscription] = useState(null);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth0();

  useEffect(() => {
    const fetchSubscription = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/get-subscription/${user.sub}`);
        setSubscription(response.data.subscription);
      } catch (error) {
        console.error("Error fetching subscription:", error);
      } finally {
        setLoading(false);
      }
    };

    if (user && user.sub) {
      fetchSubscription();
    }
  }, [user]);

  const createCheckoutSession = async (tier) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/create-checkout-session`, {
        user_id: user.sub,
        tier: tier
      });

      const { sessionId } = response.data;
      const stripe = await stripePromise;
      await stripe.redirectToCheckout({ sessionId });
    } catch (error) {
      console.error('Error creating checkout session:', error);
    }
  };

  const handlePortalSession = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/create-portal-session/${user.sub}`);
      window.location.href = response.data.url;
    } catch (error) {
      console.error("Error creating portal session:", error);
    }
  };

  if (loading) {
    return <div className="flex justify-center items-center h-screen ">
      <Spinner />
    </div>;
  }

  const renderButton = (planId, tier, buttonText) => {
    return subscription && subscription.plan.id === planId ? (
      <div className="bg-[#0C003F] text-sm px-6 py-2 rounded-md text-center text-white w-full">Current Plan</div>
    ) : (
      <Elements stripe={stripePromise}>
        <div>
          <button className="bg-[#0C003F] text-sm hover:bg-[#0c003fd6] px-6 py-2 rounded-md text-center text-white w-full mt-auto" onClick={() => createCheckoutSession(tier)}>{buttonText}</button>
        </div>
      </Elements>
    );
  };

  return (
    <div className="flex flex-col items-center ">
      <h2 className="text-3xl font-bold mb-4">Plans</h2>
      <p className="text-gray-600 mb-8 text-center">
        Our plans are built to fit the scale of your research. Upgrade to the
        next tier as your customer research grows.
      </p>
      <div className="flex flex-col ">
        <div className="flex space-x-6 mb-8 ">
          <button
            className="text-white text-sm bg-[#0C003F] rounded-md px-6 py-2"
            onClick={handlePortalSession}
          >
            View Invoices
          </button>
          <button
            onClick={handlePortalSession}
            className="text-white text-sm bg-[#0C003F] rounded-md px-6 py-2">
            Update Billing Details
          </button>
        </div>
      </div>
      <div className="flex space-x-6">
        {/* Free */}
        <div className="flex flex-col justify-between bg-gray-100 p-6 rounded-lg shadow-md w-64">
          <div>
            <h3 className="text-xl font-semibold mb-2">Free Plan</h3>
            <p className="text-gray-500 mb-4">For individuals </p>
            <div className="flex">
              <div className="text-4xl font-bold mb-6">$0</div>
              <p className="ml-2 mt-4">/ Month</p>
            </div>
            <ul className="text-left mb-6">
              <li className="mb-2">✓ 20 responses/month</li>
              <li className="mb-2">✓ 1 user</li>
              <li className="mb-2">✓ Email support</li>
            </ul>
          </div>
          {renderButton('price_1QAm9jK8hA4d0bD44wXJhK3G', 'free', 'Downgrade to Free')}
        </div>

        {/* Pro */}
        <div className="flex flex-col justify-between bg-gray-100 p-6 rounded-lg shadow-md w-64">
          <div>
            <h3 className="text-xl font-semibold mb-2">Pro Plan</h3>
            <p className="text-gray-500 mb-4">For teams that need more</p>
            <div className="flex">
              <div className="text-4xl font-bold mb-6">$99</div>
              <p className="ml-2 mt-4">/ Month</p>
            </div>
            <ul className="text-left mb-6">
              <li className="mb-2">✓ Everything in Free Plan</li>
              <li className="mb-2">✓ 200 responses/month</li>
              <li className="mb-2">✓ 5 users</li>
              <li className="mb-2">✓ Slack support</li>
              <li className="mb-12">✓ Unlimited AI Analysis</li>
            </ul>
          </div>
          {renderButton('price_1Q9wPuK8hA4d0bD4Y50JXrHi', 'pro', subscription && subscription.plan.id === 'price_1QAm9jK8hA4d0bD44wXJhK3G' ? 'Upgrade to Pro' : 'Downgrade to Pro')}
        </div>

        {/* Business */}
        <div className="flex flex-col justify-between bg-purple-100 p-6 rounded-lg shadow-md w-64">
          <div>
            <h3 className="text-xl font-semibold mb-2">Business Plan</h3>
            <p className="text-gray-500 mb-4">For teams ready to collab</p>
            <div className="flex">
              <div className="text-4xl font-bold mb-6">$249</div>
              <p className="ml-2 mt-4">/ Month</p>
            </div>
            <ul className="text-left mb-6">
              <li className="mb-2">✓ Everything in Pro Plan</li>
              <li className="mb-2">✓ 500 responses/month</li>
              <li className="mb-2">✓ Unlimited users</li>
              <li className="mb-2">✓ Priority support</li>
              <li className="mb-2">✓ Custom domain & branding</li>
            </ul>
          </div>
          {renderButton('price_1Q9wQeK8hA4d0bD4btKimYpZ', 'business', subscription && (subscription.plan.id === 'price_1QAm9jK8hA4d0bD44wXJhK3G' || subscription.plan.id === 'price_1Q9wPuK8hA4d0bD4Y50JXrHi') ? 'Upgrade to Business' : 'Downgrade to Business')}
        </div>

        {/* Enterprise Plan */}
        <div className="flex flex-col justify-between bg-blue-900 text-white p-6 rounded-lg shadow-md w-64">
          <div>
            <h3 className="text-xl font-semibold mb-2">Enterprise Plan</h3>
            <p className="text-gray-300 mb-4">For teams building at scale</p>
            <p className="text-4xl font-bold mb-6">Let's Talk</p>
            <ul className="text-left mb-6">
              <li className="mb-2">✓ Tailored response limits & seats</li>
              <li className="mb-2">✓ VIP support and outcomes manager</li>
              <li className="mb-2">✓ Single Sign-On (SSO)</li>
            </ul>
          </div>
          {subscription && subscription.plan.id === 'enterprise' ? (
            <button className="bg-blue-700 px-6 py-2 rounded-md text-center w-full hover:bg-blue-800">Current Plan</button>
          ) : (
            <a
              href="https://cal.com/curtis-lee/aptitude-enterprise-call"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-blue-700 px-6 py-2 text-sm rounded-md w-full hover:bg-blue-800 text-white block text-center"
            >
              Schedule Call
            </a>
          )}
        </div>
      </div>
      <div className="mt-4  ">
        <button onClick={handlePortalSession} className="text-red-600 mt-4">Cancel Plan</button>
      </div>
    </div>
  );
};

export default Plans;