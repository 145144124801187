import { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import axios from "axios";
import ActiveCallDetail from "./ActiveCallDetail";
import CallButton from "./call/CallButton";
import Vapi from "@vapi-ai/web";
import { isPublicKeyMissingError } from "../index.js";

//process.env.REACT_APP_VAPI_PUBLIC_KEY

const vapi = new Vapi('6105a9c9-5e7f-4328-8733-10e521271447');

const CallPage = () => {
    const [state, setState] = useState({
        connecting: false,
        connected: false,
        assistantIsSpeaking: false,
        volumeLevel: 0,
        assistantId: "",
        loading: true,
        submissions: [],
        responseId: "",
        callJSON: "",
        showPublicKeyInvalidMessage: false,
    });

    const { id } = useParams();
    const navigate = useNavigate();

    const fetchSubmissions = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/get-responses/${id}`);
            const submissions = response.data.responses.reverse();
            setState(prevState => ({
                ...prevState,
                submissions,
                responseId: submissions.length > 0 ? submissions[0].id : "",
                loading: false,
            }));
        } catch (error) {
            console.error('Error fetching submissions:', error);
            setState(prevState => ({ ...prevState, loading: false }));
        }
    };

    const fetchForm = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/get-form/${id}`);
            setState(prevState => ({ ...prevState, assistantId: response.data.form.assistant_id }));
        } catch (error) {
            console.error('Error fetching form:', error);
        }
    };

    useEffect(() => {
        fetchSubmissions();
        fetchForm();
    }, [id]);

    useEffect(() => {
        vapi.on("call-start", () => {
            setState(prevState => ({
                ...prevState,
                connecting: false,
                connected: true,
                showPublicKeyInvalidMessage: false,
            }));
        });

        vapi.on("call-end", () => {
            setState(prevState => ({
                ...prevState,
                connecting: false,
                connected: false,
                showPublicKeyInvalidMessage: false,
            }));
        });

        vapi.on("speech-start", () => {
            setState(prevState => ({ ...prevState, assistantIsSpeaking: true }));
        });

        vapi.on("speech-end", () => {
            setState(prevState => ({ ...prevState, assistantIsSpeaking: false }));
        });

        vapi.on("volume-level", (level) => {
            setState(prevState => ({ ...prevState, volumeLevel: level }));
        });

        vapi.on("error", (error) => {
            console.error(error);
            setState(prevState => ({ ...prevState, connecting: false }));
            if (isPublicKeyMissingError({ vapiError: error })) {
                setState(prevState => ({ ...prevState, showPublicKeyInvalidMessage: true }));
            }
        });
    }, []);

    const startCallInline = async () => {
        setState(prevState => ({ ...prevState, connecting: true }));
        try {
            const callJSON = await vapi.start(state.assistantId);
            setState(prevState => ({ ...prevState, callJSON }));
        } catch (error) {
            console.error("Error starting call:", error);
        }
    };

    const endCall = async () => {
        // try {
        //     const response = await fetch(`http://localhost:5000/update-response/${state.responseId}`, {
        //         method: 'PUT',
        //         headers: { 'Content-Type': 'application/json' },
        //         body: JSON.stringify({ call_id: state.callJSON.id }),
        //     });

        //     if (!response.ok) {
        //         const data = await response.json();
        //         throw new Error(data.error || 'Failed to update response');
        //     }
        // } catch (error) {
        //     console.error("Error ending call:", error);
        // }
        vapi.stop();
        navigate('/contactform/surveyintro/call/confirmation');
    };

    return (
        <div style={{ display: "flex", width: "100vw", height: "100vh", justifyContent: "center", alignItems: "center" }}>
            {!state.connected ? (
                <CallButton label="Start Interview" onClick={startCallInline} isLoading={state.connecting} />
            ) : (
                <ActiveCallDetail
                    assistantIsSpeaking={state.assistantIsSpeaking}
                    volumeLevel={state.volumeLevel}
                    onEndCallClick={endCall}
                />
            )}
            {state.showPublicKeyInvalidMessage && <PleaseSetYourPublicKeyMessage />}
        </div>
    );
};

const PleaseSetYourPublicKeyMessage = () => (
    <div style={{ position: "fixed", bottom: "25px", left: "25px", padding: "10px", color: "#fff", backgroundColor: "#f03e3e", borderRadius: "5px", boxShadow: "0 2px 5px rgba(0,0,0,0.2)" }}>
        Is your Vapi Public Key missing? (recheck your code)
    </div>
);

export default CallPage;